import React from 'react';

class App extends React.Component {
  render() {
    return (
      <div>
        <title>Dreamilady Maker</title>
        <link rel="stylesheet" href="index.css" />
{/*         <link rel="icon" type="image/png" sizes="32x32" href="images/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="images/favicon-16x16.png" />
        <link rel="mask-icon" href="images/safari-pinned-tab.svg" color="#5bbad5" /> */}
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0,shrink-to-fit=no" />
        <meta name="title" content="Dreamilady Maker NFT" />
        <meta name="keyword" content="Milady, Milady Maker, Remilia Collective, NFT, Generative Art, Dreamilady, Dreamilady Maker" />
        <meta name="description" content="Dreamilady Maker is a collection of 10,000 handdrawn generative pfpNFT's in a neochibi aesthetic inspired by street style tribes." />
        <link rel="icon" href="favicon.ico" type="image/icon type" />
        <meta property="og:title" content="Dreamilady Maker NFT" />
        <meta property="og:description" content="Dreamilady Maker is a collection of 10,000 handdrawn generative pfpNFT's in a neochibi aesthetic inspired by street style tribes." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.dreamilady.xyz" />
        <meta property="article:section" content="article" />
        <meta property="article:published_time" content="2023-05-21T00:00:00+00:00" />
        <meta property="article:modified_time" content="2023-05-21T00:00:00+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://www.dreamilady.xyz/banner.png" />
        <meta name="twitter:title" content="Dreamilady Maker NFT" />
        <meta name="twitter:site" content="dreamilady" />
        <meta name="twitter:description" content="Dreamilady Maker is a collection of 10,000 handdrawn generative pfpNFT's in a neochibi aesthetic inspired by street style tribes." />
        <meta property="og:image" content="https://www.dreamilady.xyz/banner.png" />
        <div className="mascot">
          <div className="logo" style={{margin: '1em'}}>
            <a href="https://www.scatter.art/dreamilady">
              <img src="images/banner.png" style={{border: '2px solid gray', maxWidth: '500px', padding: '0em'}} /></a>
          </div>
        </div>
        <div id="NoWallet" />
        <div className="m_box">
          <div className="m_box_bar">
            <h2>Welcome to Dreamilady Maker! — ゆっくりしていってね!</h2>
          </div>
          <p>
          Dreamilady Maker is a collection of 10,000 hand drawn generative pfpNFT's in a neochibi
            aesthetic inspired by street style tribes.
          </p>
        </div>
        <div style={{margin: '0 auto', width: '50%', padding: '0.5em', display: 'flex', justifyContent: 'center' }}>
          <audio controls autoPlay loop>
            <source src="/mp3/ost.ogg" type="audio/ogg" />
            <source src="/mp3/ost.mp3" type="audio/mpeg" />
          </audio>
        </div>
        <hr />
        {/* dont */}
        <p style={{textAlign: 'center'}}>
          {/* <a href="https://discord.gg/milady">Milady Village Community Discord</a> | */}
          <a href="https://www.scatter.art/dreamilady" target="blank">Mint</a>&nbsp;
          |
          &nbsp;<a href="https://twitter.com/dreamilady" target="blank">Dreamilady Maker Twitter</a>
        </p>
        {/* ######## NEW BOX ######## */}
        <div className="box">
          <div className="box_bar">
            <h2>Dreamilady Maker Collection</h2>
          </div>
          <br />
          <img src="images/preview.gif" />
          <p style={{textAlign: 'center'}}>
            <br />
{/*             <a href="https://opensea.io/collection/milady">
            You can view and trade the Milady collection
              on the secondary market at OpenSea
            </a> */}
            <a href="https://www.scatter.art/dreamilady">
            You can mint Dreamiladies here
            </a>
            </p>
        </div>
        {/* <hr /> */}
{/*         <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
          <div style={{border: '1px solid black', padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
            <img src="images/fruits_milady.png" />
            <p style={{marginTop: '0.5em'}}>
              Milady style is inspired by the subcultural fashion tribes of Y2K
              Japan.
            </p>
          </div>
        </div> */}
        {/* <hr /> */}
{/*         <div className="box">
          <div className="box_bar">
            <h2 id="lifestyle_experiences">Lifestyle Experiences</h2>
          </div>
          <p>
            Milady is an aesthetic, a lifestyle and a community. We are constantly
            conceptualizing and developing social extensions of the Milady vision.
          </p>
          <hr />
          <p>
            <b>Milady VRtube:</b> Miladies were designed as separate asset layers, so
            they wre readymade for Live2D rigging for use as VRtube avatars. We've rolled out Milady VTube avatars in collaboration with Hologram Labs. Just connect your wallet with <a href="https://www.hologram.xyz/">the Hologram app</a> to use any Milady in streams or videocall.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/miladyvr.png" />
              <p style={{marginTop: '0.5em'}}>
                <i>"Hey Milady Fans! Welcome to the Daily Milady!!"</i>
              </p>
            </div>
          </div>
          <hr />
          <p>
            <b>Milady Raves:</b> Holder meetups in the form of warehouse raves put on in major cities like NYC, London, Tokyo in collaboration with their local
            underground scenes.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <iframe src="https://www.youtube.com/embed/tA7DZ6mMzTQ" width={420} height={315}>
              </iframe>
              <p style={{marginTop: '0.5em'}}><a href="https://www.instagram.com/remiliaco/">Milady Rave #5 - NYC 6-24-22</a></p>
            </div>
          </div>
          <hr />
          <p>
            <b>Miladycore Bootlegs:</b> <a href="https://store.remilia.org">Bootleg merch store</a> for commissioned 
            knockoffs of designer cosmetic series with logos detourned to feature Milady graphics.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/bootleg.png" />
              <p style={{marginTop: '0.5em'}}><a href="https://store.remilia.org/collections/milady-bootlegs">The Prada bootleg buckethat</a></p>
            </div>
          </div>
          <hr />
          <p>
            <b>Milady Minecraft Metaverse:</b> Highly customized Minecraft server exclusive to the Milady community, featuring NFT-gated land plots and
            regular community events. Join by entering milady.world in Minecraft after authenticating in <a href="discord.gg/milady">Milady Village discord</a>.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/minecraft.gif" />
              <p style={{marginTop: '0.5em'}}><a href="https://discord.gg/milady">Join Milady Village</a></p>
            </div>
          </div>
        </div> */}
        {/* <hr /> */}
{/*         <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
          <div style={{border: '1px solid black', padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
            <img src="images/milady4.jpg" />
            <p style={{marginTop: '0.5em'}}>
              Milady's produce eclectic but spiritually coherent outfits.
            </p>
          </div>
        </div>
        <hr /> */}
{/*         <div className="box">
          <div className="box_bar">
            <h2 id="expansion_packs">Expansion Packs</h2>
          </div>
          <p>
            As a <a href="https://viralpubliclicense.org">copylefted</a> brand, Milady supports a thriving ecosystem of derivative projects from its fan community.
            Remilia Collective also produces official expansions given away to Milady holders following our <a href="https://goldenlight.mirror.xyz/qhsg6I_zn8o40B_Roo9Owabc_9dwAnlxFMADHM3D-Qk">hypercitational method</a>.
          </p>
          <hr />
          <p>
            <b>Banners NFT:</b> Generative NFT header photo series, a free mint offered to Milady holders, made in collaboration with the community's submitted captions.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/banners.jpg" />
              <p style={{marginTop: '0.5em'}}><a href="https://miladymaker.net/banners/">Banners NFT</a></p>
            </div>
            <p> Banners recontextualizes the userboxes of early internet personalization into modern social media platforms, with a syncretic design citing wikipedia userpages, forum signatures, and operating system themes and text content 
              detourning the basic element of userbox self-description with absurd, aesthetic and hypercitational references.</p>
          </div>
          <hr />
          <p>
            <b>Redacted Remilio Babies:</b> Companion collection to Milady Maker, as the canonical zoomer little brother to Milady. Where Milady explores Japanese style tribes, 
            Remilio uses the neochibi pfp framework to engage reactionary, schizophrenic and zoomer aesthetics.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/remilio.png" />
              <p style={{marginTop: '0.5em'}}><a href="https://remilio.org">Remilio #3452</a></p>
            </div>
            <p>Remilio's design extends one of the earliest artistic spinoffs of Milady Sonora Sprite's "OG Milady" predating the Milady Maker NFT.</p>
            <p />
          </div>
          <hr />
          <p>
            <b>Milady, That B.I.T.C.H.:</b>  Surprise free mint offered directly from contract, featuring Milady in a bootleg english t-shirt 
            each spelling out B-I-T-C-H with random words.</p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/thatbitch.png" />
              <p style={{marginTop: '0.5em'}}><a href="https://opensea.io/collection/miladybitch">Milady, That B.I.T.C.H.</a></p>
            </div>
            <p>Words used for the random generation were sourced from cultural critic Angelicism01's essay on Milady, 
              "<a href="https://cashedcobrazhousewriter.substack.com/p/no-milady-nfts-not-now-the-return">NO MILADY NFTS, NOT NOW</a>" with Chief Keef's "Finally Rich", and Chief Keef's "Kush with them Beans," as a playful and irreverent response to the environmental and capitalist criticisms made in Angelicism01's review, as a commentary on the inevitable cultural subsumption process of capitalism.
            </p></div>
          <hr />
          <p>
            <b>Pixelady Maker:</b> Pixel art derivative heavily referencing wider Milady memes and culture and general extremely online internet aesthetics, lead by <a href="https://twitter.com/egrevore">Egrevore</a> with art by <a href="https://twitter.com/MRYOKOU">Mr. Yokou</a>.
          </p>
          <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
            <div style={{padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
              <img src="images/pixelady.png" />
              <p style={{marginTop: '0.5em'}}><a href="https://twitter.com/PixeladyMaker">Pixelady #3452</a></p>
            </div>
          </div>
        </div>
        <hr /> */}
{/*         <h3>Details:</h3>
        <ul>
          <li>
            <b>Avatar-first design:</b> Illustrated from the beginning to ensure
            strong social media profile aesthetics with an emphasis on fashion. Ties
            into the companion <a href="#expansion_packs">Banner NFT</a>.
          </li>
          <li>
            <b>Chain roll function:</b> Randomized cosmetics are organized into
            <a href="#themes">different style themes</a>, with a chance of the
            consecutive generation sticking to the same theme, designed to
            organically produce aesthetic coherency.
          </li>
          <li>
            <b>Graded for Drip</b>: Milady's have ranked badges on a
            <a href="#drip_grade">variety of weighted metrics</a>, adding additional
            layers to a speculative market beyond objective trait scarcity.
          </li>
          <li>
            <b>Neochibi Aesthetic:</b> All art produced by digital painter,
            <a href="https://twitter.com/Milady_Sonoro">Milady Sonora Sprite</a>, in their
            unique childlike take on anime illustration.
          </li>
          <li>
            <b>Milady Village:</b> Members-only Discord community and designated
            launchpad for
            <a href="#expansion_packs">expansions to the Milady project</a>, such as
            generative NFT banners and mixtape series.
          </li>
        </ul> */}
{/*         <hr /> */}
        <div style={{padding: '1em', paddingLeft: '3em', paddingRight: '3em'}}>
          <div style={{border: '1px solid black', padding: '1em', fontStyle: 'italic', textAlign: 'center'}}>
            <img src="images/dreamilady3.jpg" />
            <p style={{marginTop: '0.5em'}}>We love a cute Dreamilady!</p>
          </div>
        </div>
        <div className="m_box_red">
          <div className="m_box_bar">
            <h2>About Dreamilady Maker — プリンセスメーカーについて</h2>
          </div>
          <div style={{textAlign: 'center'}}>
            <p>
              Dreamilady Maker is a project by <a href="https://twitter.com/alikonukk1">ali</a>  
              &nbsp;with art and design by   <a href="https://twitter.com/elifbozkaya0">efy</a>.
            </p>
            <hr />
            <p style={{textAlign: 'center'}}>
              {/* <a href="https://discord.gg/milady">Milady Village Community Discord</a>
              | */}
              <a href="https://www.scatter.art/dreamilady" target="blank">Mint</a>&nbsp;
              |
              &nbsp;<a href="https://twitter.com/dreamilady" target="blank">Dreamilady Maker Twitter</a>&nbsp;
              |
              &nbsp;<a href="https://etherscan.io/address/0xF3c67c8b14C203766FDBabE49105890a3Ea827De" target="blank">Contract</a>
            </p>
            <hr />
            <p>
              All Dreamilady Maker branding, assets and NFT's are copylefted under the&nbsp;
              <a href="https://viralpubliclicense.org"> Viral Public License</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default App;